import { createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode(" Check if firstName or lastName is available "),
    (_ctx.firstName || _ctx.lastName)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          title: _ctx.email
        }, [
          _createTextVNode(_toDisplayString(_ctx.completeName) + " ", 1 /* TEXT */),
          _createElementVNode("button", {
            class: "btn btn-link p-0 ms-2",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.copyToClipboard(_ctx.email))),
            title: "Copy email"
          }, [
            _createElementVNode("i", {
              class: _normalizeClass(_ctx.copiedToClipboard ? 'bi bi-clipboard-check' : 'bi bi-copy')
            }, null, 2 /* CLASS */)
          ])
        ], 8 /* PROPS */, _hoisted_1))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createCommentVNode(" If no firstName or lastName, show just the email "),
          _createElementVNode("div", null, _toDisplayString(_ctx.email), 1 /* TEXT */)
        ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
  ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
}