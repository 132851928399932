export class EmailRole {
    email: string;
    projectRole: string;

    constructor(email: string, projectRole: string) {
        this.email = email;
        this.projectRole = projectRole;
    }

}
