import { vModelText as _vModelText, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, vModelCheckbox as _vModelCheckbox, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-60531bd6"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "pm-button"
}
const _hoisted_2 = ["title"]
const _hoisted_3 = ["disabled"]
const _hoisted_4 = ["disabled"]
const _hoisted_5 = ["disabled"]
const _hoisted_6 = ["title"]
const _hoisted_7 = ["disabled"]
const _hoisted_8 = {
  key: 2,
  class: "pm-checkbox"
}
const _hoisted_9 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isActive)
    ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
        (_ctx.withMessage)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _withDirectives(_createElementVNode("input", {
                type: "text",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputText) = $event)),
                class: _normalizeClass([{ 'hidden': _ctx.hideInput }, "inputfield"]),
                placeholder: "optional message"
              }, null, 2 /* CLASS */), [
                [_vModelText, _ctx.inputText]
              ]),
              _createElementVNode("div", { title: _ctx.tooltipText }, [
                _createElementVNode("button", {
                  class: _normalizeClass([_ctx.buttonClass, 'button-spacing', { 'hidden': !_ctx.hideInput }]),
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleVisibility && _ctx.toggleVisibility(...args))),
                  disabled: _ctx.isDisabled
                }, _toDisplayString(_ctx.text), 11 /* TEXT, CLASS, PROPS */, _hoisted_3)
              ], 8 /* PROPS */, _hoisted_2),
              _createElementVNode("button", {
                class: _normalizeClass([_ctx.buttonClass, 'button-spacing', { 'hidden': _ctx.hideInput }]),
                onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleButtonClick && _ctx.handleButtonClick(...args))),
                disabled: _ctx.isDisabled
              }, "Submit", 10 /* CLASS, PROPS */, _hoisted_4),
              (!_ctx.hideInput)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    class: _normalizeClass([_ctx.buttonClass, 'button-spacing']),
                    onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleCancelClick && _ctx.handleCancelClick(...args))),
                    disabled: _ctx.isDisabled
                  }, "Cancel", 10 /* CLASS, PROPS */, _hoisted_5))
                : _createCommentVNode("v-if", true)
            ], 64 /* STABLE_FRAGMENT */))
          : (_openBlock(), _createElementBlock("div", {
              key: 1,
              title: _ctx.tooltipText
            }, [
              _createElementVNode("button", {
                class: _normalizeClass(_ctx.buttonClass),
                onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.handleButtonClick && _ctx.handleButtonClick(...args))),
                disabled: _ctx.isDisabled
              }, _toDisplayString(_ctx.text), 11 /* TEXT, CLASS, PROPS */, _hoisted_7)
            ], 8 /* PROPS */, _hoisted_6)),
        (_ctx.action2)
          ? (_openBlock(), _createElementBlock("label", _hoisted_8, [
              (!_ctx.withMessage)
                ? (_openBlock(), _createElementBlock("br", _hoisted_9))
                : _createCommentVNode("v-if", true),
              _withDirectives(_createElementVNode("input", {
                type: "checkbox",
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.checkboxChecked) = $event))
              }, null, 512 /* NEED_PATCH */), [
                [_vModelCheckbox, _ctx.checkboxChecked]
              ]),
              _createTextVNode(" " + _toDisplayString(_ctx.text2), 1 /* TEXT */)
            ]))
          : _createCommentVNode("v-if", true)
      ]))
    : _createCommentVNode("v-if", true)
}